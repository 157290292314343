import React from 'react';
import { Route } from 'react-router';
// Storefront pages
import Storefront from 'pages/Storefront/Shop';
import About from 'pages/Storefront/About';
import Shipping from 'pages/Storefront/Shipping';
import Refund from 'pages/Storefront/Refund';
import Checkout from 'pages/Storefront/Checkout';
import Order from 'pages/Storefront/Order';
import ShoppingCart from 'pages/Storefront/ShoppingCart';
import Account from 'pages/Storefront/Account';
import AccountOrders from 'pages/Storefront/Account/AccountOrders';
import AccountAddress from 'pages/Storefront/Account/AccountAddress';
import AccountSecurity from 'pages/Storefront/Account/AccountSecurity';

export const StorefrontRoutePath = {
  MAIN: '/s/:id',
  CART: '/s/:id/cart',
  CHECKOUT: '/s/:id/checkout',
  ORDER: '/s/:id/orders/:orderId',
  ABOUT: '/s/:id/about',
  SHIPPING: '/s/:id/shipping',
  REFUND_POLICY: '/s/:id/refund-policy',
  PRODUCT_DETAILS: '/s/:id/:productId',
  ACCOUNT: '/s/:id/account',
  ACCOUNT_ORDERS: '/s/:id/account/orders',
  ACCOUNT_SECURITY: '/s/:id/account/security',
  ACCOUNT_ADDRESS: '/s/:id/account/address',
};

export const StorefrontRoutes = [
  <Route key="sf_about" exact path={StorefrontRoutePath.ABOUT} component={About} />,
  <Route key="sf_shipping" exact path={StorefrontRoutePath.SHIPPING} component={Shipping} />,
  <Route key="sf_refund" exact path={StorefrontRoutePath.REFUND_POLICY} component={Refund} />,
  <Route key="sf_account" exact path={StorefrontRoutePath.ACCOUNT} component={Account} />,
  <Route key="sf_account_orders" exact path={StorefrontRoutePath.ACCOUNT_ORDERS} component={AccountOrders} />,
  <Route key="sf_account_security" exact path={StorefrontRoutePath.ACCOUNT_SECURITY} component={AccountSecurity} />,
  <Route key="sf_account_address" exact path={StorefrontRoutePath.ACCOUNT_ADDRESS} component={AccountAddress} />,
  <Route key="sf" exact path={StorefrontRoutePath.MAIN} component={Storefront} />,
  <Route key="sf_cart" exact path={StorefrontRoutePath.CART} component={ShoppingCart} />,
  <Route key="sf_checkout" exact path={StorefrontRoutePath.CHECKOUT} component={Checkout} />,
  <Route key="sf_order" exact path={StorefrontRoutePath.ORDER} component={Order} />,
  <Route key="sf_details" exact path={StorefrontRoutePath.PRODUCT_DETAILS} component={Storefront} />,
];
