import { Variation } from 'store/dashboard/catalog/items/itemsTypes';
import { Documents, Fees } from 'types/globalTypes';

import { IOrderData } from 'store/storefront/order/orderTypes';
import { zeroDecimalCurrencies } from 'constants/zeroDecimalCurrencies';
import { ReportSelectCards } from 'store/dashboard/report/reportTypes';

export const CurrencyFormatByISO = (iso = 'EUR') => (valueRaw: string | number | undefined | null): string => {
  const value = typeof valueRaw === 'string' ? Number(valueRaw) : valueRaw;

  if (value) {
    return CurrencyFormat(value, iso);
  }
  if (valueRaw === '--') {
    return CurrencyFormat(0, iso).replace('0,00', '--');
  }
  if (valueRaw === 'tbd') {
    return '--';
  }

  return CurrencyFormat(0, iso);
};

export const calcSubtotal = (data: {
  items: {
    price: number
    quantity: number
  }[]
}): number => data.items.reduce(
  (acc: number, item) => acc + item.price * item.quantity,
  0,
);

export const quoteCurrencyFormatByISO = (
  iso?: string,
) => (value?: number): string => {
  if (value !== null && value !== undefined) {
    return CurrencyFormat(value, iso);
  }
  return CurrencyFormat(0, iso).replace('0,00', '--');
};

export const totalPrice = (
  total: number,
  vatInclusive?: boolean,
  vat?: number,
): number => {
  if (!vatInclusive && vatInclusive !== undefined && vat) {
    return total + (total * vat) / 100;
  }

  return total;
};

export const totalPriceItem = (
  price: number,
  quantity: number,
  taxExcluded: number,
): number => price * quantity + taxExcluded;

export const CurrencyFormat = (price: number, currencyIso = 'EUR'): string => {
  const formatCurrency = new Intl.NumberFormat('nb-NB', {
    style: 'currency',
    currency: currencyIso,
    currencyDisplay: 'symbol',
  })
    .format(price)
    .replace(/^(\D+)/, '$1 ');

  return formatCurrency;
};

export const CurrencyFormatBrace = (
  price?: number,
  currencyIso = 'EUR',
): string => {
  const formatCurrency = new Intl.NumberFormat('nb-NB', {
    style: 'currency',
    currency: currencyIso,
    currencyDisplay: 'narrowSymbol',
  })
    .format(price || 0)
    .replace(/^(\D+)/, '$1 ');

  return formatCurrency;
};

export const CurrencyFormatRange = (
  priceRange: { min?: number; max?: number } | undefined,
  currencyIso: string,
): string => {
  if (!priceRange || !priceRange.min) {
    return CurrencyFormat(0, currencyIso);
  }

  if (priceRange.max === priceRange.min) {
    return CurrencyFormat(priceRange.max, currencyIso);
  }

  if (typeof priceRange.max !== 'undefined' && priceRange.max !== 0) {
    return `${CurrencyFormat(priceRange.min, currencyIso)} - ${CurrencyFormat(
      priceRange.max,
      currencyIso,
    )}`;
  }

  return CurrencyFormat(priceRange.min, currencyIso);
};

export const PriceRange = (
  variations: Variation[],
  currencyIso: string,
): string => {
  if (variations.length === 1) {
    return CurrencyFormat(variations[0].price, currencyIso);
  }

  let lowPrice = variations[0].price;
  let highPrice = 0;

  variations.forEach((variation) => {
    if (
      (variation.price >= lowPrice || variation.price <= lowPrice)
      && variation.price <= highPrice
    ) {
      lowPrice = variation.price;
    } else {
      highPrice = variation.price;
    }
  });

  return `${CurrencyFormat(lowPrice, currencyIso)} - ${CurrencyFormat(
    highPrice,
    currencyIso,
  )}`;
};

// format price with K for thousands and M for millions
export const PriceWithKM = (price: number, currencyIso = 'EUR'): string => new Intl.NumberFormat('nb-NB', {
  style: 'currency',
  currency: currencyIso,
  currencyDisplay: 'narrowSymbol',
  notation: 'compact',
}).format(price);

export const percentageRatio = (prev: number, curr: number): number => {
  if (curr === 0 && prev === 0) {
    return 0;
  }

  return Math.round(((curr - prev) / (prev !== 0 ? prev : curr)) * 100);
};

export interface Dataset {
  month: string;
  type?: number;
  value: number;
}

interface ResAverageMonth {
  value: number;
  percentage: number;
}

export const calcAverageMonth = (
  dataset: Dataset[] | null,
  selectedMonth: { [key: string]: unknown } | null,
): ResAverageMonth => {
  if (dataset !== null && selectedMonth !== null && dataset.length) {
    const isStacked = dataset[0].type;

    const calcSum = (arr: Dataset[], index: number) => arr.reduce(
      (acc, curr, i) => (i <= index ? acc + curr.value : acc),
      0,
    );

    const calcSumPrev = (arr: Dataset[], index: number) => arr.reduce(
      (acc, curr, i) => (i <= (index === 0 ? index : index - 1) ? acc + curr.value : acc),
      0,
    );

    if (isStacked) {
      const sumObject = dataset.reduce(
        (prev: { [key: string]: number }, curr) => {
          if (prev[curr.month]) {
            return Object.assign(prev, {
              [curr.month]: curr.value + prev[curr.month],
            });
          }
          return Object.assign(prev, { [curr.month]: curr.value });
        },
        {},
      );

      const updatedDataset = Object.keys(sumObject).map((item, index) => ({
        month: item,
        value: Object.values(sumObject)[index],
      }));

      const indexSelectedMonth = updatedDataset.findIndex((e) => e.month === selectedMonth.month);

      const sum = calcSum(updatedDataset, indexSelectedMonth);
      const sumPrev = calcSum(updatedDataset, indexSelectedMonth);
      const avrCurr = sum / (indexSelectedMonth + 1);
      const avrPrev = indexSelectedMonth === 0
        ? sumPrev / (indexSelectedMonth + 1) : sumPrev / indexSelectedMonth;

      return {
        value: avrCurr,
        percentage: percentageRatio(avrPrev, avrCurr),
      };
    }

    const iA = dataset.findIndex((e) => e.month === selectedMonth.month);
    const sum = calcSum(dataset, iA);
    const sumPrev = calcSumPrev(dataset, iA);

    const avrCurr = sum / (iA + 1);
    const avrPrev = iA === 0 ? sumPrev / (iA + 1) : sumPrev / iA;
    return {
      value: avrCurr,
      percentage: percentageRatio(avrPrev, avrCurr),
    };
  }

  return {
    value: 0,
    percentage: 0,
  };
};

export const calcReportSelectCards = (
  data: ReportSelectCards,
): ReportSelectCards => {
  if (Array.isArray(data)) {
    if (data.some((el) => el.entrytype)) {
      return data.reduce(
        (prev: { [key: string]: number }, curr) => Object.assign(prev, {
          [curr.entrytype as string]: {
            entrytotal: curr.entrytotal,
            change: curr.change,
            count: curr.entrycount
              ? curr.entrycount.toLocaleString('nb-NB')
              : curr.entrycount,
          },
        }),
        {},
      );
    }

    if (data.some((el) => el.ta.type)) {
      return data.reduce(
        (prev: { [key: string]: number }, curr) => Object.assign(prev, {
          [curr.ta.type as string]: {
            entrytotal: curr.taxtotal
              ? curr.taxtotal.toLocaleString('nb-NB')
              : curr.taxtotal,
            change: curr.change,
          },
        }),
        {},
      );
    }

    return data.reduce((prev: { [key: string]: number }, curr) => {
      if (Array.isArray(curr)) {
        return curr.reduce(
          (p: { [key: string]: number }, c) => Object.assign(p, {
            [c.label as string]: {
              entrytotal: c.totalamount,
              change: c.change,
              count: c.entrycount
                ? c.entrycount.toLocaleString('nb-NB')
                : c.entrycount,
            },
          }),
          {},
        );
      }

      return Object.assign(prev, {
        [curr.label as string]: {
          entrytotal: curr.totalamount,
          change: curr.change,
          count: curr.entrycount
            ? curr.entrycount.toLocaleString('nb-NB')
            : curr.entrycount,
        },
      });
    }, {});
  }
  return data;
};

export const calcFees = (fees?: Fees[]): number => {
  if (!fees) {
    return 0;
  }

  return fees.reduce(
    (acc, curr) => acc + Number(Object.keys(curr)[0] ? curr[Object.keys(curr)[0]] : 0),
    0,
  );
};

export const percent = (num = 0, total = 1): number => (num * 100) / total;

export const calcTotalFees = (fees?: Fees[] | null): number => {
  if (fees) {
    return fees.reduce((acc, curr) => acc + Object.values(curr)[0], 0);
  }

  return 0;
};

export const calcAverageMonthStackedChart = (
  dataset: Dataset[] | null,
  selectedMonth: Record<string, unknown> | null,
): {
  '1-30': {
    change: number;
    value: number;
  };
  '30+': {
    change: number;
    value: number;
  };
} | null => {
  if (!dataset) {
    return null;
  }

  const filterToDataset = dataset
    .reduce((acc: Dataset[][], curr) => {
      if (curr?.type) {
        acc[curr.type] = acc[curr?.type] || [];
        acc[curr.type].push(curr);
      }
      return acc;
    }, [])
    .filter(Boolean);

  const findSelectMonths = filterToDataset
    .map((item) => {
      const sortedDataset = item.sort((a, b) => a.value - b.value);
      const findIndexSelectMonth = sortedDataset.findIndex(
        (i) => i.month === selectedMonth?.month,
      );

      return {
        currentMonth: sortedDataset[findIndexSelectMonth],
        prevMonth:
          sortedDataset[
            findIndexSelectMonth === 0
              ? findIndexSelectMonth
              : findIndexSelectMonth - 1
          ],
      };
    })
    .sort((a, b) => (a?.currentMonth.type || 0) - (b?.currentMonth.type || 0));

  if (findSelectMonths) {
    const calcPercent = (a: number, b: number) => {
      const value = ((b - a) / a) * 100;

      if (Number.isNaN(value)) {
        return 0;
      }

      if (!Number.isFinite(value)) {
        return Number((((b - 1) / 1) * 100).toFixed(2));
      }

      return Number(value.toFixed(2));
    };

    return {
      '1-30': {
        value: findSelectMonths[0]?.currentMonth.value,
        change: calcPercent(
          findSelectMonths[0]?.prevMonth.value,
          findSelectMonths[0]?.currentMonth.value,
        ),
      },

      '30+': {
        value: findSelectMonths[1]?.currentMonth.value,
        change: calcPercent(
          findSelectMonths[1]?.prevMonth.value,
          findSelectMonths[1]?.currentMonth.value,
        ),
      },
    };
  }

  return null;
};
