import { OrderItem } from 'store/storefront/checkout/checkoutTypes';
import { Items } from 'types/globalTypes';

export class PaymentLinkFunctions {
  static convertItemsToOrderItems(
    items: Items[],
  ): OrderItem[] {
    return items.map((item) => ({
      name: item.name,
      category: item.categoryId || '',
      description: item.description,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      image: item.src && item.src.length !== 0 ? item.src![0] : '',
      price: item.price,
      unit: item.unitId || '',
      quantity: item.quantity,
      sku: item.sku || '',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      locationId: '',
      options: item.options.map((option) => ({
        name: option.name,
        variant: option.variant,
      })),
      taxes: item.taxes,
    }));
  }
}
