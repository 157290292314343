import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Components
import OrderLayout from 'layouts/StorefrontLayout/OrderLayout';
import LoadingPage from 'pages/LoadingPage';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import Summary from './Summary';
import DeliveryContact from './DeliveryContact';
import OrderItems from './OrderItems';
import Payment from './Payment';
import CustomerInfo from './CustomerInfo';
import TradingHours from './TradingHours';
// Styles

const Order: React.FC = () => {
  const { orderId } = useParams<{ id: string, orderId: string }>();
  const [tradingHoursModal, setTradingHoursModal] = useState(false);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const { data } = useTypeSelector(({ storefront }) => storefront.order);
  const { order: translations, cart } = useTranslationsStorefront();
  const orderStatus = useMemo(
    () => (data?.balance !== 0 && !data?.payments ? 'UNPAID' : data.status.name),
    [data?.balance, data?.payments, data?.status.name],
  );

  const handelTradingHoursModal = useCallback(() => {
    setTradingHoursModal((prev) => !prev);
  }, []);

  if (!data || !cart) {
    return (
      <OrderLayout
        contacts={currentSalesChannel?.customerService}
      >
        <LoadingPage />
      </OrderLayout>
    );
  }

  return (
    <>
      <OrderLayout
        contacts={currentSalesChannel?.customerService}
      >
        <Summary
          date={data.date}
          status={orderStatus}
          fulfillmentType={data.fulfillment.type}
          paymentDue={data?.terms?.paymentDueDate}
        />
        <CustomerInfo
          status={orderStatus}
          contact={data.contact}
          fulfillmentType={data.fulfillment.type}
          fulfillment={data.fulfillment}
        />
        <OrderItems
          items={data.items}
          currencyIso={data.currencyISO}
          translation={cart?.cart_item}
          status={orderStatus}
        />
        <Payment
          status={orderStatus}
          fulfillmentType={data.fulfillment.type}
        />
        <DeliveryContact
          fulfillmentMethod={data.fulfillment.type}
          translation={translations.shipping_info}
          status={orderStatus}
          fulfillment={data.fulfillment}
          orderId={orderId}
          handleTradingHoursModal={handelTradingHoursModal}
        />
      </OrderLayout>
      <TradingHours active={tradingHoursModal} handleModal={handelTradingHoursModal} />
    </>
  );
};

export default Order;
