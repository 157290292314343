import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Stripe
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
// Constants
import { PaymentsOptions, PayMethodsEnum } from 'constants/paymentsMethods';
import supportStripeCountries from 'data/support-countries-stripe-list.json';
// Hooks
import { useQuery } from 'hooks/useQuery';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Utils
import { PaymentFunctions } from 'utils/storefront/PaymentFunctions';
// Actions
import {
  getStripeKeys,
  invoicePaymentError,
  invoicePaymentLoading,
  invoicePaymentSuccess,
  setStripeKeys,
  SuccessPayment,
} from 'store/invoice/invoiceActions';
// Types
import { SalesChannel } from 'store/storefront/shop/shopTypes';
import {
  CheckoutType,
} from 'store/storefront/checkout/checkoutTypes';
// Assets
import { ReactComponent as BankIcon } from 'assets/payment-icons/bank.svg';
// Components
import Modal from 'components/Modal';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import FooterPaymentModal from 'components/StorefrontComponents/Checkout/FooterPaymentModal';
import PaymentTitle from 'components/StorefrontComponents/PaymentTitle';
import { getCountryIso } from 'utils/functions';
import { InvoicePaymentLoadingEnum } from 'store/invoice/invoiceTypes';
import { IOrganisationData } from 'types/globalTypes';
import Payment from './Payment';
import ExpressPayments from './ExpressPayments';
import PaymentCard from './PaymentCard';
import PaymentLaterOptionsModal from './PaymentLaterOptionsModal';
import BankPaymentOptionModal from './BankPaymentOptionModal';
// Styles
import classes from './PaymentModal.module.scss';

type Modals = 'bankPayment' | 'payLater' | 'stripe';

interface IParams {
  id: string;
  publicId: string;
}

interface Props {
  active: boolean;
  setActive: () => void;
  payAmount: number;
  location: SalesChannel | null;
  organisation?: IOrganisationData;
}

const PaymentModal: React.FC<Props> = ({
  active,
  setActive,
  payAmount,
  location,
  organisation,
}) => {
  const { id, publicId } = useParams<IParams>();
  const translations = useTranslationsStorefront();
  const paymentModalTs = translations.checkout.payment_modal;
  const bankPaymentTs = translations.checkout.payment_card_bank_payment;
  const payLaterTs = translations.checkout.payment_card_pay_later;
  const currencyIso = location?.address.currencyISO || organisation?.address?.currencyISO || 'EUR';
  const { data, stripe } = useTypeSelector(
    (state) => state.invoice,
  );
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [activeModal, setActiveModal] = useState({
    bankPayment: false,
    payLater: false,
    stripe: false,
  });

  const [type, setType] = useState<PayMethodsEnum | null>(null);
  const checkSupportStripe = useMemo(
    () => supportStripeCountries.find(
      (country) => country === (location?.address.countryISO || organisation?.address?.countryISO),
    ),
    [location?.address.countryISO, organisation?.address?.countryISO],
  );

  const banks = useMemo(
    () => PaymentFunctions.bankPayments(
      PaymentsOptions,
      location?.address?.countryISO || organisation?.address?.countryISO || 'US',
      currencyIso,
    ),
    [currencyIso, location?.address?.countryISO, organisation?.address?.countryISO],
  );

  const payLaterOptions = useMemo(
    () => PaymentFunctions.payLaterPayments(
      PaymentsOptions,
      location?.address?.countryISO || organisation?.address?.countryISO || 'US',
      currencyIso,
      getCountryIso(data?.contact.address?.country)?.value,
    ),
    [
      currencyIso,
      data?.contact.address?.country,
      location?.address?.countryISO,
      organisation?.address?.countryISO,
    ],
  );

  const allPayMethods = useMemo(() => {
    const payMethodsBanks = banks.map((b) => b.name);
    const payMethodsPayLater = payLaterOptions
      .map((b) => b.name)
      .filter((name) => name !== PayMethodsEnum.PAY_LATER);
    return [...payMethodsBanks, ...payMethodsPayLater, PayMethodsEnum.CARD];
  }, [banks, payLaterOptions]);

  const handleError = useCallback(
    (error) => {
      dispatch(invoicePaymentError(error));
    },
    [dispatch],
  );

  const handleSuccess = useCallback(() => {
    dispatch(invoicePaymentSuccess(true));
    setActive();
  }, [dispatch, setActive]);

  const iStripe = useCallback(async () => {
    if (stripe?.key && stripe?.stripeAccount) {
      setStripePromise(
        await loadStripe(stripe.key, {
          stripeAccount: stripe.stripeAccount,
        }),
      );
    } else {
      handleError('Stripe is not available');
    }
  }, [stripe, handleError]);

  const handleModal = useCallback((modal: Modals) => {
    setActiveModal((prev) => ({
      ...prev,
      bankPayment: false,
      payLater: false,
      stripe: false,
      [modal]: !prev[modal],
    }));
  }, []);

  const handleLoading = useCallback(
    (load) => {
      dispatch(invoicePaymentLoading(load));
    },
    [dispatch],
  );

  const handlePayment = useCallback(() => {
    handleModal('stripe');
    setActive();
  }, [handleModal, setActive]);

  const getStripe = useCallback((checkoutType: CheckoutType = 'INSTANT') => {
    dispatch(getStripeKeys(id, payAmount, allPayMethods, checkoutType));
  }, [allPayMethods, dispatch, id, payAmount]);

  useEffect(() => {
    if (stripe) {
      iStripe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  const handlePay = useCallback(
    async (paymentMethodTypes: PayMethodsEnum) => {
      setActive();
      handleLoading('PAY');
      if ((location || organisation) && !stripe) {
        getStripe();
        handleModal('stripe');
      }

      setType(paymentMethodTypes);
      if (stripe) {
        handleModal('stripe');
        handleLoading('');
      }
    },
    [setActive, handleLoading, location, organisation, stripe, getStripe, handleModal],
  );

  useEffect(() => {
    const cSecret = query.get('payment_intent_client_secret');
    if (stripe) {
      setStripeKeys({
        ...stripe,
        client_secret: cSecret || undefined,
      });
    }
  }, [query, stripe]);

  useEffect(() => {
    const stripeAcc = query.get('acc');
    const key = query.get('stripe');
    const redirectStatus = query.get('redirect_status');
    if (stripeAcc && key) {
      dispatch(
        setStripeKeys({
          ...stripe,
          stripeAccount: stripeAcc,
          key,
        }),
      );

      query.delete('acc');
      query.delete('stripe');
      history.replace({
        search: query.toString(),
      });
    }

    if (redirectStatus === 'pending') {
      dispatch(SuccessPayment(id, publicId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SuccessPayment, query]);

  return (
    <>
      <Modal
        active={active}
        setActive={() => true}
        className={classes.payment_modal}
      >
        <div className={classes.root}>
          <HeaderModal onClick={setActive} className={classes.header} />
          <PaymentTitle currencyIso={currencyIso} value={payAmount} />
          <p className={classes.subtitle}>{paymentModalTs.subtitle}</p>
          <div className={classes.express_card}>
            <div className={classes.express_card_title}>
              {paymentModalTs.express_payment_text}
            </div>
            <ExpressPayments
              handlePay={handlePay}
              checkSupportStripe={!!checkSupportStripe}
              currencyIso={currencyIso}
              countryIso={getCountryIso(data?.contact.address?.country)?.value}
              total={payAmount}
              handleError={handleError}
              handleLoading={handleLoading}
              handleSuccess={handleSuccess}
            />
          </div>
          <p className={classes.or}>{paymentModalTs.or}</p>
          <div className={classes.payment_cards}>
            <PaymentCard
              title={translations.checkout.payment_card_card_payment.title}
              subtitle={
                translations.checkout.payment_card_card_payment.subtitle
              }
              isStripe
              logos={
                PaymentsOptions.find(
                  (option) => option.name === PayMethodsEnum.CARD,
                )?.logos
              }
              btnTitle={
                translations.checkout.payment_card_card_payment.btn_title
              }
              handlePay={handlePay}
              type={PayMethodsEnum.CARD}
            />
            <PaymentCard
              title={bankPaymentTs.title}
              subtitle={bankPaymentTs.subtitle}
              isStripe
              btnTitle={bankPaymentTs.btn_title}
              handleClick={() => handleModal('bankPayment')}
            >
              <BankIcon />
            </PaymentCard>
            <PaymentCard
              title={payLaterTs.title}
              subtitle={payLaterTs.subtitle}
              text={payLaterTs.descr}
              btnTitle={payLaterTs.btn_title}
              isStripe={false}
              handleClick={() => handleModal('payLater')}
            />
          </div>
          <FooterPaymentModal disabled />
        </div>
      </Modal>
      {stripePromise && stripe?.client_secret && (
        <Elements stripe={stripePromise}>
          <Payment
            active={activeModal.stripe}
            setActive={handlePayment}
            clientSecret={stripe.client_secret}
            type={type}
            total={payAmount}
            handleError={handleError}
            onSuccess={handleSuccess}
            handleLoading={() => handleLoading(InvoicePaymentLoadingEnum.PAY)}
          />
        </Elements>
      )}
      <BankPaymentOptionModal
        active={activeModal.bankPayment}
        setActive={() => handleModal('bankPayment')}
        banks={banks}
        allPayMethods={allPayMethods}
        total={payAmount}
        stripePromise={stripePromise}
        getStripe={getStripe}
        handleError={handleError}
        handleSuccess={handleSuccess}
        handleLoading={handleLoading}
      />
      <PaymentLaterOptionsModal
        active={activeModal.payLater}
        setActive={() => handleModal('payLater')}
        allPayMethods={allPayMethods}
        stripePromise={stripePromise}
        payLaterOptions={payLaterOptions}
        stripe={stripe}
        getStripe={getStripe}
        handleSuccess={handleSuccess}
        handleLoading={handleLoading}
        total={payAmount}
        currencyIso={currencyIso}
        paymentTerm={location?.checkout.payLater}
      />
    </>
  );
};

export default PaymentModal;
