/* eslint-disable no-console */
import { AppThunk } from 'store';
// API_URL
import { API, ApiOrgPublicId } from 'services/api';
import {
  Documents,
  Payments,
  TransactionStatus,
} from 'types/globalTypes';
import { dateFormatA } from 'utils/dates';
import { calcTaxes, getStatus, isEmptyObject } from 'utils/functions';
import { AxiosResponse } from 'axios';
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { PayMethodsEnum } from 'constants/paymentsMethods';
import { CheckoutType } from 'store/storefront/checkout/checkoutTypes';
import { calcSubtotal, calcTotalFees } from 'utils/price';
import {
  INVOICE_LOADING,
  InvoiceActions,
  INVOICE_GET_DATA,
  INVOICE_SET_CLIENT_SECRET,
  SET_PAYMENT_STATUS,
  StripeKeys,
  StatusPayment,
  InvoiceState,
  SET_PAYMENT_SCHEDULE,
  INVOICE_PAYMENT_LOADING,
  INVOICE_PAYMENT_ERROR,
  INVOICE_PAYMENT_SUCCESS,
  InvoicePaymentLoadingEnum,
} from './invoiceTypes';

export const invoiceLoadingAction = (payload: boolean): InvoiceActions => ({
  type: INVOICE_LOADING,
  payload,
});

const invoiceGetDataAction = (
  payload: InvoiceState['data'],
): InvoiceActions => ({
  type: INVOICE_GET_DATA,
  payload,
});

export const setStripeKeys = (payload: StripeKeys): InvoiceActions => ({
  type: INVOICE_SET_CLIENT_SECRET,
  payload,
});

export const setPaymentSchedule = (payload: StripeKeys): InvoiceActions => ({
  type: INVOICE_SET_CLIENT_SECRET,
  payload,
});

export const setStatusPayment = (payload: {
  loading: boolean;
  status: StatusPayment;
}): InvoiceActions => ({
  type: SET_PAYMENT_STATUS,
  payload,
});

export const setPaymentScheduleStatus = (payload: boolean): InvoiceActions => ({
  type: SET_PAYMENT_SCHEDULE,
  payload,
});

export const invoicePaymentLoading = (payload: string): InvoiceActions => ({
  type: INVOICE_PAYMENT_LOADING,
  payload,
});

export const invoicePaymentError = (error: string): InvoiceActions => ({
  type: INVOICE_PAYMENT_ERROR,
  payload: error,
});

export const invoicePaymentSuccess = (payload: boolean): InvoiceActions => ({
  type: INVOICE_PAYMENT_SUCCESS,
  payload,
});

export const getInvoiceData = (id: string, publicId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(invoiceLoadingAction(true));
    const organisation = await ApiOrgPublicId('/p/organisations', publicId);
    const { data }: { data: Documents } = await API.get(`/p/invoices/${id}`);
    const findSalesChannel = await ApiOrgPublicId(
      `/p/locations/${data.location || 'default'}`,
      publicId,
    );

    findSalesChannel.address.currencyISO = organisation.currencyISO;

    const totalFees = calcTotalFees(data.fees);
    const taxes = calcTaxes(data.items);
    const dueDate = () => {
      if (data.terms?.paymentSchedule && data.terms) {
        return data.terms?.paymentSchedule[
          (data.terms.paymentSchedule.length || 0) - 1
        ]?.date;
      }

      return 0;
    };

    if (data) {
      const subtotal = calcSubtotal(data);
      const invoiceData = {
        organisation: data.organisation,
        date: new Date(data.date),
        dueDate: data.dueDate,
        updateAt: data.updateAt,
        contact: {
          fullName: `${data?.contact?.firstName || ''} ${
            data?.contact?.lastName || ''
          }`,
          ...data?.contact,
        },
        details: {
          createdAt: dateFormatA(data.createdAt),
          type: TransactionTypes[data.type],
          reference: data?.id?.substring(0, 5),
          invoiceDate: dateFormatA(data?.contact?.createdAt),
          status: getStatus(data.statusId),
        },
        items: data.items,
        price: {
          currencyIso: organisation.currencyISO,
          subtotal,
          discount: data.totalDiscount,
          total:
              subtotal - data.totalDiscount + totalFees + taxes.excluded,
          paymentMethod:
              data?.payments.length !== 0
                ? data?.payments[0].paymentMethod
                : '',
          fees: data.fees,
        },
        location: isEmptyObject(findSalesChannel) ? null : findSalesChannel,
        payments: data.payments,
        termsPayment: {
          dueDate: dueDate() ? dateFormatA(dueDate()) : undefined,
          instalements:
              getStatus(data.statusId) !== TransactionStatus.PAID
              && data?.terms !== null,
          notes: data.notes,
          payments: data?.payments
            ? data.payments.sort(
              (a, b) => (Date.parse(a.createdAt) <= Date.parse(b.createdAt) ? -1 : 1),
            )
            : [],
          remainingBalance: data.balance,
          amountPaid: data?.payments.length
            ? data?.payments.reduce(
              (acc: number, curr: Payments) => acc + curr.amount,
              0,
            )
            : '',
          paymentSchedule: data?.terms
            ? data.terms.paymentSchedule?.sort(
              (a, b) => (Date.parse(a.date) <= Date.parse(b.date) ? -1 : 1),
            )
            : null,
        },
      };
      dispatch(invoiceLoadingAction(false));
      dispatch(invoiceGetDataAction(invoiceData));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setSendSms = async (data: {
  message: string;
  number: unknown;
  data: {
    documentId: string;
    documentType: string;
  };
}): Promise<void> => {
  try {
    await API.post('/links', data);
  } catch (error) {
    console.error(error);
  }
};

export const getStripeKeys = (
  id: string,
  amount: number,
  paymentMethodTypes?: PayMethodsEnum[] | [],
  checkoutType?: CheckoutType,
): AppThunk => async (dispatch) => {
  try {
    dispatch(invoicePaymentLoading(InvoicePaymentLoadingEnum.PAY));
    const { data } = await API.post(`/p/invoices/${id}/payments`, {
      amount,
      paymentMethod: 'STRIPE',
      paymentMethodTypes,
      checkoutType,
    });
    dispatch(setStripeKeys(data));
    dispatch(invoicePaymentLoading(''));
  } catch (error) {
    console.error(error);
  }
};

export const createPayPalOrder = async <T>(
  amount: number,
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<T | any>> => {
  const { data } = await API.post(`/p/invoices/${id}/payments`, {
    amount,
    paymentMethod: 'PAYPAL',
  });
  invoicePaymentLoading('');
  return data;
};

export const onApprovePayPalOrder = async <T>(
  amount: number,
  data: { orderID: string },
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<T | any>> => {
  const res = await API.post(`/p/invoices/${id}/approve-payment`, {
    orderId: data.orderID,
  });
  return res;
};

export const SuccessPayment = (id: string, publicId: string): AppThunk => async (dispatch) => {
  dispatch(getInvoiceData(id, publicId));
  dispatch(
    setStatusPayment({
      loading: false,
      status: StatusPayment.resolve,
    }),
  );
};
