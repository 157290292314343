import { StripeError } from '@stripe/stripe-js';
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { PayMethodsEnum } from 'constants/paymentsMethods';
import { StripeKeys } from 'store/invoice/invoiceTypes';
import { AddressType, ContactType, TaxesRes } from 'types/globalTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
import { CartItemType, NotificationEnum, SalesChannel } from '../shop/shopTypes';

export const CHECKOUT_SET_READY = 'CHECKOUT_SET_READY';
export const CHECKOUT_SET_ITEMS = 'CHECKOUT_SET_ITEMS';
export const CHECKOUT_SET_FULFILLMENT_METHOD = 'CHECKOUT_SET_FULFILLMENT_METHOD';
export const CHECKOUT_SET_FULFILLMENT_DATA = 'CHECKOUT_SET_FULFILLMENT_DATA';
export const CHECKOUT_SET_LOCATION = 'CHECKOUT_SET_LOCATION';
export const CHECKOUT_SET_CONTACT = 'CHECKOUT_SET_CONTACT';
export const CHECKOUT_SET_PAYMENT = 'CHECKOUT_SET_PAYMENT';
export const CHECKOUT_REMOVE_ITEM = 'CHECKOUT_REMOVE_ITEM';
export const CHECKOUT_UPDATE_ITEM = 'CHECKOUT_UPDATE_ITEM';
export const CHECKOUT_SET_GEO_INFO = 'CHECKOUT_SET_GEO_INFO';
export const CHECKOUT_SET_SALES_CHANNEL = 'CHECKOUT_SET_SALES_CHANNEL';
export const CHECKOUT_SET_PRE_CHECKOUT_LOADING = 'CHECKOUT_SET_PRE_CHECKOUT_LOADING';
export const CHECKOUT_SET_IS_USER = 'CHECKOUT_SET_IS_USER';
export const CHECKOUT_SET_IS_NEW_USER = 'CHECKOUT_SET_IS_NEW_USER';
export const CHECKOUT_SET_ORDER_LOADING = 'CHECKOUT_SET_ORDER_LOADING';
export interface CheckoutState {
  isReadyForCheckout: IPreCheckout;
  isUser: boolean;
  isNewUser: boolean;
  preCheckoutLoading: boolean;
  items: CartItemType[];
  fulfillment: ICheckoutFulfillment;
  order: IOrder;
}

export interface IPreCheckout {
  success?: boolean;
  data: OrderItem[]
  errorCode: string;
}

export interface IUpdateItem {
  url: string;
  item: CartItemType
}

export type TPage = 'CustomerInfo' | 'Delivery' | 'Payment'

export enum EPage {
  INFO = 'CustomerInfo',
  DELIVERY = 'Delivery',
  PAYMENT = 'Payment'
}

export type PaymentsMethods = 'STRIPE' | 'PAYPAL' | 'PAYSTACK';

export type CheckoutType = 'INSTANT' | 'LATER';

export enum LoadingEnum {
  SET_ORDER = 'set order',
  PAY = 'pay',
  PAYPAL = 'paypal',
  SIGNUP = 'signup',
  SIGNIN = 'signin',
}

export interface IOrder {
  loading: LoadingEnum | null;
  error: string | undefined;
  orderId?: string;
  id?: string;
  placeOrder?: boolean;
  isPayed: boolean;
  loadingModal?: boolean;
  stripe?: StripeKeys
  type?: string;
}

export interface FormData {
  country: {
    label: string,
    value: string
  }
  password: string,
  firstName: string,
  lastName: string,
  line1: string,
  line2: string,
  city: string,
  postCode: string
  email: string
  code: string
  phoneNumber: string
  latitude: string
  longitude: string
}

export interface IModals {
  card: boolean,
  eps: boolean,
  ideal: boolean,
  p24: boolean,
  boleto: boolean,
  oxxo: boolean,
  konbini: boolean,
  paypal: boolean,
  googleApple: boolean,
}

export interface IAmount {
  total: number;
  subtotal: number;
  fee: number;
  taxesIncluded: number;
  taxesExcluded: number;
}

export interface IResCheckout {
  id: string;
  payment: {
    client_secret: string,
    stripeAccount: string,
    key: string
    data: any
  };
  data: any
}

export interface IFulfillment{
  name?: string;
  fee?: number;
  type: DeliveryMethodsEnum;

  trackingProvider?: string;

  trackingNumbers?: string;

  carrierFirstName?: string;

  carrierLastName?: string;

  carrierMobileNumber?: string;

  address: AddressType
}
export interface ICheckoutFulfillment {
  method: DeliveryMethodsEnum | null;
  country: {
    name: string;
    iso: string;
    currency?: string;
  };
  location: {
    name: string;
    address: AddressType;
  };
  provider?: {
    name: string;
    url: string;
  }
  deliveryDriver?: {
    name: string;
    phone: string;
  }
  amount: IAmount;
  contact: ContactType;
}

export interface IPayModalProps {
  translation: TranslationStorefrontModel['checkout']['card_form'];
  handleSuccess: () => void;
  handleError: (error: StripeError | undefined | unknown) => void;
  handleLoading: (loading: LoadingEnum | null) => void;
  handleNotificationError: (typeNotification: NotificationEnum) => void;
  clientSecret?: string;
  active: boolean;
  setActive: () => void;
}

export interface OrderItem {
  name: string;
  category: string;
  description: string;
  price: number;
  unit: string;
  quantity: number;
  sku: string;
  locationId: string;
  options: { name: string, variant: string }[];
  taxes?: TaxesRes[]
  image?: string;
}

export interface RequestCheckoutBody {
  total: number,
  totalTax: number,
  checkoutType: CheckoutType,
  paymentMethod: PaymentsMethods,
  paymentMethodTypes?: PayMethodsEnum[],
  fulfillment: IFulfillment,
  items: OrderItem[],
  location: string,
  contactId: string,
  terms?: {
    paymentDueDate: string,
  },
  balance: number,
}

interface CheckoutSetReadyAction {
  type: typeof CHECKOUT_SET_READY;
  payload: IPreCheckout;
}

interface CheckoutSetItemsAction {
  type: typeof CHECKOUT_SET_ITEMS;
  payload: CartItemType[];
}

interface CheckoutSetFulfilmentMethodAction {
  type: typeof CHECKOUT_SET_FULFILLMENT_METHOD;
  payload: DeliveryMethodsEnum;
}

interface CheckoutSetDeliveryDataAction {
  type: typeof CHECKOUT_SET_FULFILLMENT_DATA;
  payload: ICheckoutFulfillment;
}

interface CheckoutSetLocationAction {
  type: typeof CHECKOUT_SET_LOCATION;
  payload: ICheckoutFulfillment['location'];
}

interface CheckoutSetContactAction {
  type: typeof CHECKOUT_SET_CONTACT;
  payload: ICheckoutFulfillment['contact'];
}

interface CheckoutSetPaymentAction {
  type: typeof CHECKOUT_SET_PAYMENT;
  payload: IOrder;
}
interface CheckoutRemoveAction {
  type: typeof CHECKOUT_REMOVE_ITEM;
  payload: IUpdateItem;
}

interface CheckoutUpdateQuantityItemAction {
  type: typeof CHECKOUT_UPDATE_ITEM;
  payload: IUpdateItem;
}

interface CheckoutSetSalesChannelAction {
  type: typeof CHECKOUT_SET_SALES_CHANNEL;
  payload: SalesChannel;
}

interface CheckoutSetPreCheckoutLoadingAction {
  type: typeof CHECKOUT_SET_PRE_CHECKOUT_LOADING;
  payload: boolean;
}

interface CheckoutSetIsUserAction {
  type: typeof CHECKOUT_SET_IS_USER;
  payload: boolean;
}
interface CheckoutSetIsNewUserAction {
  type: typeof CHECKOUT_SET_IS_NEW_USER;
  payload: boolean;
}

interface CheckoutSetOrderLoadingAction {
  type: typeof CHECKOUT_SET_ORDER_LOADING;
  payload: LoadingEnum | null;
}

export type CheckoutActions =
  CheckoutSetReadyAction |
  CheckoutSetPreCheckoutLoadingAction |
  CheckoutSetItemsAction |
  CheckoutSetFulfilmentMethodAction |
  CheckoutSetDeliveryDataAction |
  CheckoutSetLocationAction |
  CheckoutSetContactAction |
  CheckoutSetPaymentAction |
  CheckoutUpdateQuantityItemAction |
  CheckoutSetSalesChannelAction |
  CheckoutSetIsUserAction |
  CheckoutSetIsNewUserAction |
  CheckoutSetOrderLoadingAction |
  CheckoutRemoveAction;
